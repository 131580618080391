import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import NotFoundImg from '../images/index/not-found.png'
import { Button, Layout } from '@dayancloud/common/components'
import { color, Media } from '@dayancloud/common/theme'

const NotFoundContained = styled.div`
  width: 100%;
  display: flex;
  padding: 200px 0;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    padding: 150px 20px 80px;
    img {
      width: 100%;
      height: auto;
    }
  }
`
const RightTextWrap = styled.div`
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 34px;
  a {
    width: 160px;
    height: 50px;
    line-height: 46px;
    border: 2px solid ${color.primary};
    border-radius: 25px;
    font-size: 20px;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    margin: 20px 0;
    height: auto;
    a {
      width: auto;
      height: auto;
      font-size: 16px;
    }
  }
`
const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: ${color.primary};
  ${Media.lessThan(Media.small)} {
    font-size: 20px;
  }
`
const ReasonWrap = styled.div`
  margin: 30px 0;
  .title {
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 20px;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    margin: 0;
    .title {
      font-size: 18px;
      margin: 10px 0;
    }
  }
`

class NotFound extends React.PureComponent {
  render() {
    const {
      location,
      pageContext: { topFiveNews },
    } = this.props
    return (
      <Layout location={location} topFiveNews={topFiveNews}>
        <NotFoundContained>
          <div className='img-wrap'>
            <img src={NotFoundImg} alt='' />
          </div>
          <RightTextWrap>
            <Title>出错啦!</Title>
            <ReasonWrap>
              <div className='title'>sorry!您访问的地址有误或者页面不存在…</div>
            </ReasonWrap>
            <Button variant='outlined' as={Link} to='/'>
              回到首页
            </Button>
          </RightTextWrap>
        </NotFoundContained>
      </Layout>
    )
  }
}

export default NotFound
